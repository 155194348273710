<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard
          title="Datos de facturacion"
          subtitle="Listado de empresas ingresadas por el cliente"
        ></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="buyer.facturacion"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No hay empresas ingresadas"
          :loading="loading"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-html="props.item.rut"></td>
              <td v-html="props.item.razon_social"></td>
              <td v-html="props.item.giro"></td>
              <td v-html="props.item.comuna"></td>
              <td>
                <v-icon
                  v-if="user.role.id === 1 || user.role.id === 2"
                  small
                  class="mr-2"
                  @click="editFacturacion(props.item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="
                    (user.role.id === 1 || user.role.id === 2) &&
                      user.shop.admin
                  "
                  small
                  @click="deleteItem(props.item)"
                >
                  delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'BuyerSii',
  components: { titleCard },
  props: ['buyer'],
  data: () => ({
    headers: [
      { text: 'Rut', align: 'left', sortable: false },
      { text: 'Razon Social', align: 'left', sortable: false },
      { text: 'Giro', align: 'left', sortable: false },
      { text: 'Comuna', align: 'left', sortable: false },
      { text: '', align: 'left', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    editFacturacion(item) {
      console.log('Editar', item)
    },
    deleteItem(item) {
      console.log('Eliminar', item)
    }
  }
}
</script>

<style></style>
